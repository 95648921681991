import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header cursor-pointer" }
const _hoisted_3 = { class: "card-body p-9" }
const _hoisted_4 = { class: "notice d-flex bg-light-warning rounded border-warning border border-dashed p-6" }
const _hoisted_5 = { class: "svg-icon svg-icon-2tx svg-icon-warning me-4" }
const _hoisted_6 = { class: "row gy-10 gx-xl-10" }
const _hoisted_7 = { class: "col-xl-6" }
const _hoisted_8 = { class: "col-xl-6" }
const _hoisted_9 = { class: "row gy-10 gx-xl-10" }
const _hoisted_10 = { class: "col-xl-6" }
const _hoisted_11 = { class: "col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTChartWidget1 = _resolveComponent("KTChartWidget1")!
  const _component_KTListWidget1 = _resolveComponent("KTListWidget1")!
  const _component_KTListWidget5 = _resolveComponent("KTListWidget5")!
  const _component_KTTableWidget5 = _resolveComponent("KTTableWidget5")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title m-0" }, [
          _createElementVNode("h3", { class: "fw-bolder m-0" }, "Profile Details")
        ], -1)),
        _createVNode(_component_router_link, {
          to: "/account/settings",
          class: "btn btn-primary align-self-center"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Edit Profile")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"row mb-7\"><label class=\"col-lg-4 fw-bold text-muted\">Full Name</label><div class=\"col-lg-8\"><span class=\"fw-bolder fs-6 text-dark\">Max Smith</span></div></div><div class=\"row mb-7\"><label class=\"col-lg-4 fw-bold text-muted\">Company</label><div class=\"col-lg-8 fv-row\"><span class=\"fw-bold fs-6\">Keenthemes</span></div></div><div class=\"row mb-7\"><label class=\"col-lg-4 fw-bold text-muted\"> Contact Phone <i class=\"fas fa-exclamation-circle ms-1 fs-7\" data-bs-toggle=\"tooltip\" title=\"Phone number must be active\"></i></label><div class=\"col-lg-8 d-flex align-items-center\"><span class=\"fw-bolder fs-6 me-2\">044 3276 454 935</span><span class=\"badge badge-success\">Verified</span></div></div><div class=\"row mb-7\"><label class=\"col-lg-4 fw-bold text-muted\">Company Site</label><div class=\"col-lg-8\"><a href=\"#\" class=\"fw-bold fs-6 text-dark text-hover-primary\">keenthemes.com</a></div></div><div class=\"row mb-7\"><label class=\"col-lg-4 fw-bold text-muted\"> Country <i class=\"fas fa-exclamation-circle ms-1 fs-7\" data-bs-toggle=\"tooltip\" title=\"Country of origination\"></i></label><div class=\"col-lg-8\"><span class=\"fw-bolder fs-6 text-dark\">Germany</span></div></div><div class=\"row mb-7\"><label class=\"col-lg-4 fw-bold text-muted\">Communication</label><div class=\"col-lg-8\"><span class=\"fw-bolder fs-6 text-dark\">Email, Phone</span></div></div><div class=\"row mb-10\"><label class=\"col-lg-4 fw-bold text-muted\">Allow Changes</label><div class=\"col-lg-8\"><span class=\"fw-bold fs-6\">Yes</span></div></div>", 7)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen044.svg" })
          ]),
          _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"d-flex flex-stack flex-grow-1\"><div class=\"fw-bold\"><h4 class=\"text-gray-800 fw-bolder\">We need your attention!</h4><div class=\"fs-6 text-gray-600\"> Your payment was declined. To start using tools, please <a class=\"fw-bolder\" href=\"#\">Add Payment Method</a>. </div></div></div>", 1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_KTChartWidget1, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-10" })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_KTListWidget1, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-10'" })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_KTListWidget5, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-10" })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_KTTableWidget5, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-10" })
      ])
    ])
  ], 64))
}